import React from 'react';

import Layout from 'components/Layout';
import Helmet from 'components/Helmet';
import styles from './404.module.scss';
import { SlideDownThenUp } from '../components/Transition';
import Link from 'components/Link';

const NotFoundPage = () => (
  <Layout>
    <Helmet title='404: Not found' />
    <SlideDownThenUp>
      <div className={styles.fourOhFour}>
        <h1 className={styles.code}>
          <span className={styles.emoji} role='img' aria-label='sad face'>
            😟
          </span>{' '}
          404{' '}
          <span className={styles.emoji} role='img' aria-label='skull & crossbones'>
            ☠️
          </span>
        </h1>
        <h2 className={styles.oops}>Well, this is embarrassing.</h2>
        <p className={styles.explanation}>
          We're sorry but we can't find the page you requested. <br />
          Click <Link to={'/'}>here</Link> to go back to the home page.
        </p>
      </div>
    </SlideDownThenUp>
  </Layout>
);

export default NotFoundPage;
